import { default as indexwwvQ9aQboTMeta } from "/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/account/custom/index.vue?macro=true";
import { default as packagesAPKSw2mqrwMeta } from "/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/account/custom/packages.vue?macro=true";
import { default as partsA3nZIFbuBrMeta } from "/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/account/custom/parts.vue?macro=true";
import { default as customAtpfd2G300Meta } from "/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/account/custom.vue?macro=true";
import { default as indextsHNDib8brMeta } from "/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/account/index.vue?macro=true";
import { default as ratesTxNfFmfMm3Meta } from "/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/account/rates.vue?macro=true";
import { default as subscriptionIzwaikJFErMeta } from "/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/account/subscription.vue?macro=true";
import { default as accountPjbE7Git5eMeta } from "/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/account.vue?macro=true";
import { default as _91id_93jG48tJM3F9Meta } from "/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/activite/clients/edit/[id].vue?macro=true";
import { default as indexvPZ3MLfvLMMeta } from "/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/activite/clients/index.vue?macro=true";
import { default as _91id_93KrsFx0DihmMeta } from "/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/activite/clients/show/[id].vue?macro=true";
import { default as _91id_93gZ4TCmhB5NMeta } from "/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/activite/commandes/[id].vue?macro=true";
import { default as indexvrmfGfkRFmMeta } from "/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/activite/commandes/index.vue?macro=true";
import { default as vehiculesm0GVRaBWRsMeta } from "/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/activite/vehicules.vue?macro=true";
import { default as _91title_93_45_91id_93b2kTT8q8WtMeta } from "/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/Boutique/[title]-[id].vue?macro=true";
import { default as indexE7nltBfo0XMeta } from "/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/Boutique/index.vue?macro=true";
import { default as productsIRlRWKxB78Meta } from "/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/Boutique/products.vue?macro=true";
import { default as indexwa265soJZTMeta } from "/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/catalog/index.vue?macro=true";
import { default as configurationSiiN4dj2Z0Meta } from "/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/catalog/maintenance/configuration.vue?macro=true";
import { default as indexZSr4hO5yXkMeta } from "/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/catalog/maintenance/index.vue?macro=true";
import { default as planThodLnfJJqMeta } from "/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/catalog/maintenance/plan.vue?macro=true";
import { default as works5LLJdP7HlVMeta } from "/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/catalog/maintenance/works.vue?macro=true";
import { default as maintenancefwKCPPo9xGMeta } from "/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/catalog/maintenance.vue?macro=true";
import { default as manualsN26bz4Z59MMeta } from "/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/catalog/manuals.vue?macro=true";
import { default as timesL09DELIFtaMeta } from "/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/catalog/times.vue?macro=true";
import { default as catalogNNCKZ106ulMeta } from "/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/catalog.vue?macro=true";
import { default as commandeokUDGWajSbMeta } from "/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/commande.vue?macro=true";
import { default as _91tab_93OlP0IbSfjUMeta } from "/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/devis/[tab].vue?macro=true";
import { default as _91id_93OW0p7gSpiHMeta } from "/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/devis/edit/[id].vue?macro=true";
import { default as _91id_93oWl62WdR0hMeta } from "/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/devis/show/[id].vue?macro=true";
import { default as imprimer_45pdfKHRD5g8sLkMeta } from "/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/imprimer-pdf.vue?macro=true";
import { default as indexpfx0tXL5nJMeta } from "/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/index.vue?macro=true";
import { default as loginJCiiGXKTFvMeta } from "/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/login.vue?macro=true";
import { default as panieryW7YGzribUMeta } from "/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/panier.vue?macro=true";
import { default as indexfGsBboxU36Meta } from "/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/pneumatique/index.vue?macro=true";
import { default as productsCLvjPOofT2Meta } from "/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/pneumatique/products.vue?macro=true";
import { default as pneumatiquetLNQHjaT7lMeta } from "/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/pneumatique.vue?macro=true";
import { default as maintenanceZJRuGWJOUZMeta } from "/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/print/maintenance.vue?macro=true";
import { default as productseYroh1P7FuMeta } from "/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/products.vue?macro=true";
import { default as signuplkNjpncwOKMeta } from "/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/signup.vue?macro=true";
import { default as tableau_45de_45bord1iQMgJUZzfMeta } from "/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/tableau-de-bord.vue?macro=true";
export default [
  {
    name: accountPjbE7Git5eMeta?.name,
    path: "/account",
    component: () => import("/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/account.vue"),
    children: [
  {
    name: customAtpfd2G300Meta?.name,
    path: "custom",
    component: () => import("/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/account/custom.vue"),
    children: [
  {
    name: "account-custom",
    path: "",
    component: () => import("/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/account/custom/index.vue")
  },
  {
    name: "account-custom-packages",
    path: "packages",
    component: () => import("/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/account/custom/packages.vue")
  },
  {
    name: "account-custom-parts",
    path: "parts",
    component: () => import("/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/account/custom/parts.vue")
  }
]
  },
  {
    name: "account",
    path: "",
    component: () => import("/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/account/index.vue")
  },
  {
    name: "account-rates",
    path: "rates",
    component: () => import("/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/account/rates.vue")
  },
  {
    name: "account-subscription",
    path: "subscription",
    component: () => import("/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/account/subscription.vue")
  }
]
  },
  {
    name: "activite-clients-edit-id",
    path: "/activite/clients/edit/:id()",
    component: () => import("/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/activite/clients/edit/[id].vue")
  },
  {
    name: "activite-clients",
    path: "/activite/clients",
    component: () => import("/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/activite/clients/index.vue")
  },
  {
    name: "activite-clients-show-id",
    path: "/activite/clients/show/:id()",
    component: () => import("/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/activite/clients/show/[id].vue")
  },
  {
    name: "activite-commandes-id",
    path: "/activite/commandes/:id()",
    component: () => import("/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/activite/commandes/[id].vue")
  },
  {
    name: "activite-commandes",
    path: "/activite/commandes",
    component: () => import("/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/activite/commandes/index.vue")
  },
  {
    name: "activite-vehicules",
    path: "/activite/vehicules",
    component: () => import("/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/activite/vehicules.vue")
  },
  {
    name: "Boutique-title-id",
    path: "/Boutique/:title()-:id()",
    component: () => import("/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/Boutique/[title]-[id].vue")
  },
  {
    name: "Boutique",
    path: "/Boutique",
    component: () => import("/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/Boutique/index.vue")
  },
  {
    name: "Boutique-products",
    path: "/Boutique/products",
    component: () => import("/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/Boutique/products.vue")
  },
  {
    name: catalogNNCKZ106ulMeta?.name,
    path: "/catalog",
    component: () => import("/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/catalog.vue"),
    children: [
  {
    name: "catalog",
    path: "",
    component: () => import("/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/catalog/index.vue")
  },
  {
    name: maintenancefwKCPPo9xGMeta?.name,
    path: "maintenance",
    component: () => import("/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/catalog/maintenance.vue"),
    children: [
  {
    name: "catalog-maintenance-configuration",
    path: "configuration",
    component: () => import("/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/catalog/maintenance/configuration.vue")
  },
  {
    name: "catalog-maintenance",
    path: "",
    component: () => import("/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/catalog/maintenance/index.vue")
  },
  {
    name: "catalog-maintenance-plan",
    path: "plan",
    component: () => import("/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/catalog/maintenance/plan.vue")
  },
  {
    name: "catalog-maintenance-works",
    path: "works",
    component: () => import("/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/catalog/maintenance/works.vue")
  }
]
  },
  {
    name: "catalog-manuals",
    path: "manuals",
    component: () => import("/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/catalog/manuals.vue")
  },
  {
    name: "catalog-times",
    path: "times",
    component: () => import("/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/catalog/times.vue")
  }
]
  },
  {
    name: "commande",
    path: "/commande",
    component: () => import("/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/commande.vue")
  },
  {
    name: "devis-tab",
    path: "/devis/:tab()",
    component: () => import("/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/devis/[tab].vue")
  },
  {
    name: "devis-edit-id",
    path: "/devis/edit/:id()",
    component: () => import("/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/devis/edit/[id].vue")
  },
  {
    name: "devis-show-id",
    path: "/devis/show/:id()",
    component: () => import("/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/devis/show/[id].vue")
  },
  {
    name: "imprimer-pdf",
    path: "/imprimer-pdf",
    meta: imprimer_45pdfKHRD5g8sLkMeta || {},
    component: () => import("/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/imprimer-pdf.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginJCiiGXKTFvMeta || {},
    component: () => import("/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/login.vue")
  },
  {
    name: "panier",
    path: "/panier",
    component: () => import("/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/panier.vue")
  },
  {
    name: pneumatiquetLNQHjaT7lMeta?.name,
    path: "/pneumatique",
    component: () => import("/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/pneumatique.vue"),
    children: [
  {
    name: "pneumatique",
    path: "",
    component: () => import("/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/pneumatique/index.vue")
  },
  {
    name: "pneumatique-products",
    path: "products",
    component: () => import("/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/pneumatique/products.vue")
  }
]
  },
  {
    name: "print-maintenance",
    path: "/print/maintenance",
    meta: maintenanceZJRuGWJOUZMeta || {},
    component: () => import("/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/print/maintenance.vue")
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/products.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signuplkNjpncwOKMeta || {},
    component: () => import("/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/signup.vue")
  },
  {
    name: "tableau-de-bord",
    path: "/tableau-de-bord",
    component: () => import("/var/www/vhosts/apa-trading.fr/app.apa-trading.fr/pages/tableau-de-bord.vue")
  }
]